import React, { useEffect } from 'react';
import './styles.scss';

function Legal() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="legal">
      <h1>Legal Notice</h1>
      <p>
        In accordance with the provisions of Law No. 2004-575 of June 21, 2004 on confidence in the digital economy,
        users of the Exotic site are informed of the identity of the various parties involved in its implementation and
        monitoring.
      </p>
      <div>
        <h2>Site Publishing</h2>
      </div>
      <p>
        This site, accessible at the URL www.exotic.paris (the “Site”), is published by:&nbsp;
        Exotic SAS, a company with a capital of 600 euros, registered with the R.C.S. of PARIS under number 979060118,
        represented by Ignat Sleta duly authorized.&nbsp;
        The publisher&apos;s individual VAT number is: FR93979060118.
      </p>
      <div>
        <h2>Hosting</h2>
      </div>
      <p>
        The Site is hosted by Microsoft Azure, located at 39 QUAI DU PRESIDENT ROOSEVELT 92130 ISSY LES MOULINEAUX,
        (telephone contact: +33970019090).
      </p>
      <div>
        <h2>Publication Director</h2>
      </div>
      <p>
        The Publication Director of the Site is Ignat Sleta.
      </p>
      <div>
        <h2>Contact Us</h2>
      </div>
      <p>
        By email: contact@exotic.paris
      </p>
      <div>
        <h2>Personal Data</h2>
      </div>
      <p>
        The processing of your personal data is governed by our Privacy Policy, available from the &quot;Personal Data
        Protection Charter&quot; section, in accordance with the General Data Protection Regulation 2016/679 of April
        27, 2016 ( “GDPR”).
      </p>
    </div>
  );
}

export default Legal;
