import React from 'react';
import logo from '../assets/images/logo_exotic.png';
import './styles.scss';

function Home() {
  return (
    <div className="company_text">
      <img alt="Exotic Paris logo" src={logo} />
      <div className="company_vision">
        <p>
          Nous croyons que chaque instant compte, TOUS !
        </p>
        <p>
          Nous nous consacrons &agrave; r&eacute;volutionner l&apos;expérience aux toilettes, en transformant ces moments souvent n&eacute;glig&eacute;s en opportunit&eacute;s de r&eacute;flexion, de d&eacute;tente et de recentrage sur soi.
        </p>
      </div>
    </div>
  );
}

export default Home;
