import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

// import { FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa';

function Footer() {
  return (
    <div className="footer">
      <div className="left_content">
        <Link to="/">
          <p>Home</p>
        </Link>
        <Link to="/legal-notice">
          <p>Legal Notice</p>
        </Link>
        <Link to="/terms-of-use">
          <p>Terms of Use</p>
        </Link>
        <Link to="/privacy">
          <p>Privacy Policy</p>
        </Link>
      </div>
      <div>
        <p className="contact">
          <a href="mailto:contact@exotic.paris">contact@exotic.paris</a>
        </p>
      </div>
      <div className="copyright">
        <p>2024 - Copyright Exotic</p>
      </div>
    </div>
  );
}

export default Footer;
